import { useStaticQuery, graphql } from 'gatsby'

export const useHomeVideoSectionQuery = () => {
const { wpPage } = useStaticQuery(
    graphql`
  query HomeVideoSectionQuery{
    wpPage(slug: { eq: "home" }) {
      home {
        videoSectionTitleBlackPartOne
        videoSectionTitleRed
        videoSectionTitleBlackPartTwo
        videoSectionSubtitle
        videoSectionH1
        videoSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 100)
            }
          }
        }
        videoSectionImageAltText
        videoSectionParagraph
    }
    }
  }
  `
);
return wpPage.home;
}