import { useStaticQuery, graphql } from 'gatsby'

export const useBannerQuery = () => {
const { wpPage } = useStaticQuery(
    graphql`
    query BannerQuery {
      wpPage(slug: {eq: "home"}) {
        home {
          bannerImageAltText
          bannerWelcomeMessage
          bannerQuoteWhiteText
          bannerQuoteRedText
          bannerSubheading
          bannerLinks {
            bannerLinksTitle
            bannerLinksExcerpt
            bannerLinksLink {
              url
            }
          }
          bannerImageDesktop {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          bannerImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
    
  `
);
return wpPage;
}