import { useStaticQuery, graphql } from 'gatsby'

export const useHomeSeoQuery = () => {
const { wpPage } = useStaticQuery(
    graphql`
  query HomeSeoQuery{
    wpPage(slug: {eq: "home"}) {
        seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphUrl
            opengraphSiteName
            opengraphType
            opengraphImage {
              localFile {
                publicURL
              }
            }
            canonical
            twitterTitle
            twitterDescription
            twitterImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
    }
  }
  `
);
return wpPage.seo;
}