import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useBannerQuery } from '../../hooks/use-banner-query'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { fadeInLeftBig } from 'react-animations'
import { Link } from "gatsby"
import { Theme2, Device } from "../Layout"
import { ReviewsWidgetCircular } from "../ReviewsWidgetCircular"
import { BannerCards } from "../BannerCards"


const bounceAnimation = keyframes`${fadeInLeftBig}`;

const BannerContainer = styled.div`

.bannerDesktop{
  display: none;
  

  @media ${Device.lg}{
    display: grid;
color: ${Theme2.colors.light};
background-color: ${Theme2.colors.dark1};
position: relative;

  }
}
.bannerMobile{
  display: grid;
color: ${Theme2.colors.light};
background-color: ${Theme2.colors.dark1};
position: relative;
  @media ${Device.lg}{
    display: none;
  }
}
.gatsby-image-wrapper img[style]{
    height: auto !important;
  }
svg{
  max-width: 100%;
}

.overBackgroundImage{
  grid-area: 1/1;
  position: relative;

 
}
.bannerPracticeAreas{
  position: relative;
  

  svg{
    position: absolute;
  }
  @media ${Device.lg}{
    overflow-y: hidden;
    position: static;
  }
}

.textContainerOuter{
  min-height: 507px;
  display: grid;
  align-items: center;
  color: ${Theme2.colors.light};
  max-width: 75rem;
  padding: 0 2rem;
  margin: 0 auto;
}
.textContainerInner{
  margin-top: 190px;
  max-width: 624px;
  

  @media ${Device.lg}{
    margin: 120px 0;
    animation: 1s ${bounceAnimation};
  }
}
.bannerWelcomeMessage{
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;

  @media ${Device.sm}{
    font-size: calc(12px + 13 * ((100vw - 576px) / 824));
  }
  @media ${Device.xxl}{
    font-size: 25px;
  }
}
.bannerQuoteText{
font-family: ${Theme2.fonts.headings};
font-weight: 700;
font-size: 38px;
line-height: 1.1;
@media ${Device.sm}{
    font-size: calc(38px + 47 * ((100vw - 576px) / 824));
  }
  @media ${Device.xxl}{
    font-size: 85px;
  }
  .red{
    color: ${Theme2.colors.tertiary};
  }
}
.subheadingContainer{
  display: grid;
  grid-template-columns: 79px auto;
  grid-column-gap: 17px;
  align-items: center;

  .line{
    width: 79px;
    height: 2px;
    background-color: ${Theme2.colors.light};
  }
  .subheading{
    font-family: ${Theme2.fonts.alternateHeadings};
    font-style: italic;
    font-size: 22px;
    line-height: 1;
    @media ${Device.sm}{
    font-size: calc(22px + 18 * ((100vw - 576px) / 824));
  }
  @media ${Device.xxl}{
    font-size: 40px;
  }
  }
}
.mobileImage{
  @media ${Device.lg}{
    display: none;
  }
}
.desktopImage{
  display: none;
  @media ${Device.lg}{
    display: block;
  }
}
.mobileRedDecorativeElement{
  position: absolute;
  @media ${Device.lg}{
    display: none;
  }
}
.redDecorativeElement{
  display: none;
  @media ${Device.lg}{
    display: inline-block;
    position: absolute;
    bottom: 0;
  }
}
img{
  max-width: 100%;
}
@media ${Device.lg}{
    align-items: flex-end;
  }
.reviewsWidgetContainer{
  position: absolute;
  left: 5%;
  top: 0;
  @media ${Device.lg}{
    left: auto;
    right: 5%;
  }
}

`


const Banner = () =>{

  const data = useBannerQuery()
  const mobileImage = data.home.bannerImageMobile.localFile.childImageSharp.gatsbyImageData.images.sources[1].srcSet
  const desktopImage = data.home.bannerImageDesktop.localFile.childImageSharp.gatsbyImageData.images.sources[1].srcSet
 
    return(
      <>
      <BannerContainer id="top">
        <div className="bannerDesktop">
        <div className="reviewsWidgetContainer">
          <Link to='/review-us/'>
        <ReviewsWidgetCircular instance="1" />
        </Link>
        </div>
        <img className="desktopImage" srcSet={desktopImage} style={{
          gridArea: "1/1",
          alignSelf: "start"
        }} alt={data.home.bannerImageAltText} />
        
        <div className="overBackgroundImage">
          <div className="textContainerOuter">
           <div className="textContainerInner">
            <div className="bannerWelcomeMessage">{data.home.bannerWelcomeMessage}</div>
        <div className="bannerQuoteText">"{data.home.bannerQuoteWhiteText} <span className="red">{data.home.bannerQuoteRedText}</span>"</div>
        <div className="subheadingContainer">
<div className="line"></div>
<div className="subheading">{data.home.bannerSubheading}</div>
        </div>
        
        </div>
          </div>


  
        <div className="bannerPracticeAreas">
          <StaticImage className="redDecorativeElement" src="../../images/redDecorativeElement.png" alt="" formats={["auto", "webp", "avif"]} quality={100} loading="eager" />
          
         <BannerCards />
          
    </div>
  


  </div>
  </div>
  <div className="bannerMobile">
        <div className="reviewsWidgetContainer">
          <Link to='/review-us/'>
        <ReviewsWidgetCircular instance="2" />
        </Link>
        </div>
        <img className="mobileImage" srcSet={mobileImage} style={{
          gridArea: "1/1",
          alignSelf: "start"
        }} alt={data.home.bannerImageAltText}/> 
       
        
        <div className="overBackgroundImage">
          <div className="textContainerOuter">
           <div className="textContainerInner">
            <div className="bannerWelcomeMessage">{data.home.bannerWelcomeMessage}</div>
        <div className="bannerQuoteText">"{data.home.bannerQuoteWhiteText} <span className="red">{data.home.bannerQuoteRedText}</span>"</div>
        <div className="subheadingContainer">
<div className="line"></div>
<div className="subheading">{data.home.bannerSubheading}</div>
        </div>
        
        </div>
          </div>


 
        <div className="bannerPracticeAreas">
        <StaticImage className="mobileRedDecorativeElement" src="../../images/mobileRedDecorativeElement.png" alt="" formats={["auto", "webp", "avif"]} quality={100} loading="eager" />
         
          
         <BannerCards />
          
    </div>



  </div>
  </div>
        </BannerContainer>
        </>
    )
}

export default Banner