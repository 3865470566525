import React from "react"
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { css } from "@emotion/react";
import { pulse } from 'react-animations';
import { useInView } from 'react-intersection-observer'
import { Theme2, Device } from "../Layout"
import { useHomeVideoSectionQuery } from '../../hooks/use-home-video-section-query'




const HomeVideoSectionOuterContainer = styled.div`
display: grid;
position: relative;
box-sizing: border-box;
text-align: center;
overflow-y: hidden;



.helpingWhite{
  position: absolute;
  height: 100%;
}
.homeVideoSectionInnerContainer{
  grid-area: 1/1;
  position: relative;
   max-width: 75rem;
  padding: 3rem 2rem 4rem 2rem;
  margin: 0 auto;
  overflow-y: hidden;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-content: center;
}
.homeVideoSectionTitle{
  line-height: 1.1;
font-family: ${Theme2.fonts.headings};
font-size: 35px;
font-weight: 700;
margin-bottom: 1rem;
max-width: 818px;
margin: 0 auto;


@media ${Device.sm}{
font-size: calc(35px + 45 * ((100vw - 576px) / 624));
}
@media ${Device.xl}{
  font-size: 80px;
  margin-bottom: 2rem;
}

  .red{
color: ${Theme2.colors.tertiary};
  }
}
.homeVideoSectionSubtitle{
font-family: ${Theme2.fonts.alternateHeadings};
font-size: 24px;
font-style: italic;
color: ${Theme2.colors.primary};
max-width: 818px;
margin: 0 auto 1rem auto;

@media ${Device.sm}{
font-size: calc(24px + 8 * ((100vw - 576px) / 624));
}
@media ${Device.xl}{
  font-size: 32px;
}
}
.homeH1{
  font-size: 13px;
  margin-bottom: 1rem;
  @media ${Device.sm}{
font-size: calc(13px + 3 * ((100vw - 576px) / 624));
}
@media ${Device.xl}{
  font-size: 16px;
}
}
.videoSectionParagraph{
  margin-top: 1.6rem;
  display: inline-block;
}
`


const HomeVideoSection = () =>{
  const { videoSectionTitleBlackPartOne, videoSectionTitleRed, videoSectionTitleBlackPartTwo, videoSectionSubtitle, videoSectionH1, videoSectionImage, videoSectionImageAltText, videoSectionParagraph } = useHomeVideoSectionQuery()
  
 
    return(
        <HomeVideoSectionOuterContainer >
        
        
       
        <div className="homeVideoSectionInnerContainer">
          <div>
        <p className="homeVideoSectionTitle ">{videoSectionTitleBlackPartOne} <span className="red">{videoSectionTitleRed}</span> {videoSectionTitleBlackPartTwo}</p>
        <p className="homeVideoSectionSubtitle">{videoSectionSubtitle}</p>
        <h1 className="homeH1">{videoSectionH1}</h1>
        <img css={css`
        max-width: 100%;

        @media ${Device.lg}{
          max-width: 865px;
        }
        `} srcSet={videoSectionImage.localFile.childImageSharp.gatsbyImageData.images.sources[1].srcSet} alt={videoSectionImageAltText} />
        <div className="videoSectionParagraph" dangerouslySetInnerHTML={{__html: videoSectionParagraph}} />
        </div>
        </div>
       
        </HomeVideoSectionOuterContainer>
    )
}

export default HomeVideoSection