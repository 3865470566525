import React, { Suspense, lazy } from "react"
import { graphql } from "gatsby"
import { useHomeSeoQuery } from "../hooks/use-home-seo-query"
import { Seo } from "../components/Seo"
import { Layout } from "../components/Layout"
import { HeaderDesktop } from "../components/HeaderDesktop"
import { HeaderMobile } from "../components/HeaderMobile"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { BackToTop } from "../components/BackToTop"
import { HomeVideoSection } from "../components/HomeVideoSection"
import { Banner } from "../components/Banner"

const TVShowLogos = lazy(() =>
  import("../components/TVShowLogos").then(module => ({
    default: module.TVShowLogos,
  }))
)
const Results = lazy(() =>
  import("../components/Results").then(module => ({
    default: module.Results,
  }))
)
const PracticeAreas = lazy(() =>
  import("../components/PracticeAreas").then(module => ({
    default: module.PracticeAreas,
  }))
)
const VideoCarousel = lazy(() =>
  import("../components/VideoCarousel").then(module => ({
    default: module.VideoCarousel,
  }))
)
const Awards = lazy(() =>
  import("../components/Awards").then(module => ({
    default: module.Awards,
  }))
)
const Blog = lazy(() =>
  import("../components/Blog").then(module => ({
    default: module.Blog,
  }))
)
const ServiceAreasSection = lazy(() =>
  import("../components/ServiceAreasSection").then(module => ({
    default: module.ServiceAreasSection,
  }))
)
const Map = lazy(() =>
  import("../components/Map").then(module => ({
    default: module.Map,
  }))
)
const HomeFormContainer = lazy(() =>
  import("../components/HomeFormContainer").then(module => ({
    default: module.HomeFormContainer,
  }))
)
const Footer = lazy(() =>
  import("../components/Footer").then(module => ({
    default: module.Footer,
  }))
)

const Home = ({ data }) => {
  const seo = useHomeSeoQuery()

  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Seo data={seo} />
      <Layout>
        <HeaderMobile />
        <HeaderDesktop />
        <Banner />
        <Suspense fallback="loading">
          <HomeVideoSection />
        </Suspense>
        <Suspense fallback="loading">
          <TVShowLogos />
        </Suspense>
        <Suspense fallback="loading">
          <Results />
        </Suspense>
        <Suspense fallback="loading">
          <PracticeAreas />
        </Suspense>
        <Suspense fallback="loading">
          <VideoCarousel />
        </Suspense>
        <Suspense fallback="loading">
          <Awards />
        </Suspense>
        <Suspense fallback="loading">
          <Blog data={data} />
        </Suspense>
        <Suspense fallback="loading">
          <ServiceAreasSection isHomePage={true} />
        </Suspense>
        <Suspense fallback="loading">
          <Map />
        </Suspense>
        <Suspense fallback="loading">
          <HomeFormContainer />
        </Suspense>
        <Suspense fallback="loading">
          <Footer />
        </Suspense>
        <BackToTop showBelow={250} />
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    allWpPost(limit: 6, sort: { fields: date, order: DESC }) {
      edges {
        node {
          excerpt
          date(formatString: "MMM DD, YYYY")
          title
          link
        }
      }
    }
    wpPage(title: { eq: "Home" }) {
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Home
