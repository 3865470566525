import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled/macro'
import { Theme2 } from "../Layout"
import { Ribbon } from "../SVGs"

const ReviewsOuterContainer = styled.div`
position: relative;
padding-top: 10px;

.ribbonContainer{
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
`
const ReviewsContainer = styled.div`
background-color: ${Theme2.colors.tertiary};
color: #fff;
box-shadow: 5px 5px 8px -4px rgba(0,0,0,.3);
font-family: sans-serif;
font-size: 13px;
font-weight: 700;
box-sizing: border-box;
padding: 20px;
width: 140px;
height: 140px;
border-radius: 50%;
text-transform: none;
text-align: center;
border: 5px solid ${Theme2.colors.light};
z-index: 2;
position: relative;
`

const SvgContainer = styled.div`
margin-top: 7px;
svg{
  height: 13px;
}
`

const ReviewsNumber = styled.div`
font-size: 30px;
font-weight: 400;
`

const ReviewsWidgetCircular = ({instance}) => {

    const data = useStaticQuery(graphql`
    {
        query1: allBirdeyedata(filter: {id: {ne: "dummy"}, sourceType: {ne: "Direct Feedback"}}) {
          nodes {
            rating
          }
        }
        query2: allBirdeyedata(filter: {id: {ne: "dummy"}, rating: {ne: 0}}) {
          nodes {
            rating
          }
        }
      }
  `)
 
  function length(obj) {
    return Object.keys(obj).length;
}
const totalRatings = length (data.query1.nodes)
const fiveFourThreeRatings = length (data.query2.nodes)

 //console.log(totalRatings)
 //console.log(fiveFourThreeRatings)


 let total = 0
 const ratingsTotal = data.query1.nodes
 let i
for (i = 0; i < ratingsTotal.length; i++) {  
 total += ratingsTotal[i].rating;  
}
//console.log(total);  

let averageRating = total / fiveFourThreeRatings
averageRating = averageRating.toFixed(1)
//console.log(averageRating)

let rectWidth = averageRating * 20 + '%'
//console.log(rectWidth)



  return(
    <ReviewsOuterContainer>
      <div className="ribbonContainer">
<Ribbon />
      </div>
  <ReviewsContainer>
    <ReviewsNumber>
    {totalRatings}
    </ReviewsNumber>
  Client Reviews

  <SvgContainer>
  <svg viewBox="0 0 1000 200" class='rating'>
						    <defs>
						      
      <polygon id="star" points="100,0 131,66 200,76 150,128 162,200 100,166 38,200 50,128 0,76 69,66 "/>
                  
						      <clipPath id={'stars'+instance}>
						        <use xlinkHref="#star"/>
						        <use xlinkHref="#star" x="20%"/>
						        <use xlinkHref="#star" x="40%"/>
						        <use xlinkHref="#star" x="60%"/>
						        <use xlinkHref="#star" x="80%"/>
						      </clipPath>
                  
						    </defs>
						  
    <rect style={{fill: "#dddddd", height: "100%", width: "100%"}} clip-path={'url(#stars'+instance+')'}></rect>
  
  
						    <rect width={rectWidth} style={{fill: "#EDBE2B", height: "100%"}} clip-path={'url(#stars'+instance+')'}></rect>
  
</svg> 
</SvgContainer>
  </ReviewsContainer>
  </ReviewsOuterContainer>
  )
}
export default ReviewsWidgetCircular