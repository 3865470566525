import React, { useState } from "react"
import styled from '@emotion/styled/macro'
import { Link } from "gatsby"
import { CriminalDefense, PersonalInjury, FamilyLaw } from "../SVGs"
import { Theme2, Device } from "../Layout"
import { useBannerQuery } from '../../hooks/use-banner-query'

const BannerPracticeAreasInner = styled.div`
 padding: 44px 0 0 0;
  
  @media ${Device.lg}{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
  }

.bannerPracticeAreaInner{
border: 1px solid ${Theme2.colors.neutral};
text-align: center;
padding: 25px;
position: relative;


svg{
  position: relative;
}
.criminalDefense{
  max-width: 100px;
  fill: ${Theme2.colors.light};
}
.personalInjury{
max-width: 92px;
stroke: ${Theme2.colors.light};
}
.familyLaw{
max-width: 100px;
stroke: ${Theme2.colors.light};
}

  p{
    color: ${Theme2.colors.neutral};
    font-size: 16px;
    position: relative;
    margin: 0;
  }
  .title{
    color: ${Theme2.colors.light};
    font-family: ${Theme2.fonts.headings};
    font-size: 18px;
    font-weight: 700;
    position: relative;
    margin: 30px 0 18px 0;
  }
}
.bannerPracticeArea{
  background-color: ${Theme2.colors.dark4};
  position: relative;
  max-width: 307px;
  margin: 0 auto 20px auto;
  padding: 20px;
  box-sizing: border-box;
  transition: all .5s;

  &:hover{
    background-color: ${Theme2.colors.light2};
    zoom: 1.1;

    .criminalDefense{
  fill: ${Theme2.colors.dark1};
}
    .personalInjury{
stroke: ${Theme2.colors.dark1};
}
.familyLaw{
stroke: ${Theme2.colors.dark1};
}
    p{
      color: ${Theme2.colors.dark1};
    }
    .title{
      color: ${Theme2.colors.dark1};
    }
    .bannerPracticeAreaInner{
      border: 1px solid ${Theme2.colors.light};
    }
  }
  @media ${Device.lg}{
    min-height: 384px;
  }
}
.on{
  background-color: ${Theme2.colors.light2};
    zoom: 1.1;

    .criminalDefense{
  fill: ${Theme2.colors.dark1};
}
    p{
      color: ${Theme2.colors.dark1};
    }
    .title{
      color: ${Theme2.colors.dark1};
    }
    .bannerPracticeAreaInner{
      border: 1px solid ${Theme2.colors.light};
    }
}
`
   
  
const BannerCards = () =>{
    const data = useBannerQuery()

  const [othersHovered, setOthersHovered] = useState(false);

    return(
<BannerPracticeAreasInner>
          <Link to={data.home.bannerLinks[0].bannerLinksLink.url}>
    <div className={othersHovered ? "bannerPracticeArea first" : "bannerPracticeArea first on"} >
      
    <div className="bannerPracticeAreaInner">
    <CriminalDefense className="criminalDefense" />
      
    <p className="title">{data.home.bannerLinks[0].bannerLinksTitle}</p>
    <p>{data.home.bannerLinks[0].bannerLinksExcerpt}</p>
    </div>
</div>
</Link>
    <Link to={data.home.bannerLinks[1].bannerLinksLink.url}>
    <div className="bannerPracticeArea second" onMouseEnter={() => setOthersHovered(true)} onMouseLeave={() => setOthersHovered(false)}>
      
    <div className="bannerPracticeAreaInner">
      
      <PersonalInjury className="personalInjury" />
    
    <p className="title">{data.home.bannerLinks[1].bannerLinksTitle}</p>
    <p>{data.home.bannerLinks[1].bannerLinksExcerpt}</p>
    </div>
</div>
</Link>
<Link to={data.home.bannerLinks[2].bannerLinksLink.url}>
<div className="bannerPracticeArea third" onMouseEnter={() => setOthersHovered(true)} onMouseLeave={() => setOthersHovered(false)}>
<div className="bannerPracticeAreaInner">
 
  <FamilyLaw className="familyLaw" />
  
    <p className="title">{data.home.bannerLinks[2].bannerLinksTitle}</p>
    <p>{data.home.bannerLinks[2].bannerLinksExcerpt}</p>
    </div>
    </div>
    </Link>
    </BannerPracticeAreasInner>
    )
}
export default BannerCards